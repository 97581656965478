import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";

import Layout from "../components/Layout.jsx";
import Map from "../components/Map.jsx";
import Contact from "../components/Contact.jsx";
import Features from "../components/Features.jsx";
import Logos from "../components/Logos.jsx";
import Stats from "../components/Stats.jsx";

import api from "../apis/public";

const IndexPage = () => {
  useEffect(() => {
    //getAnnouncement();
  }, []);

  const getAnnouncement = async () => {
    try {
      let announcement = await api.getAnnouncement();
      PubSub.publish('announcement', announcement);
    } catch(err) {
      console.log(err);
    }
  }

  return (
    <Layout>
      <section id="home">
        <div className="pt-0 pb-4 bg-gray-50 sm:pt-12 md:pt-8">
          <div className="mx-auto px-4 sm:px-6">
            <div className="text-center">
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                COVID-19
                <br />
                <span className="text-indigo-600">Vaccine Locator</span>
              </h2>
              <p className="mt-3 mb-4 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                The #1 real-time COVID-19 vaccine text and email alert system. Easily search for nearby vaccine availability based on your zip code.
                {/* <a href="#providers" className="text-indigo-600 font-semibold">See a list of providers we check.</a> */}
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section id="map" className="bg-gray-50 pt-4 sm:pt-6">
        <Map />
      </section>

      <section id="stats" className="bg-gray-50 pt-12 sm:pt-18">
        <Stats />
      </section>

      <section id="features" className="bg-gray-50 pt-12 sm:pt-18">
        <Features />
      </section>

      <section id="providers" className="bg-gray-50 pt-12 sm:pt-18">
        <Logos />
      </section>

      <section id="contact" className="bg-gray-50 pt-12 sm:pt-18">
        <Contact />
      </section>
    </Layout>
  )
}

export default IndexPage
