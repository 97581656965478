import React, { useEffect, useState } from "react";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';

import api from "../apis/public";

const Stats = () => {
  const [ stats, setStats ] = useState(null);

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    try {
      // let stats = await api.getStats();
      // setStats(stats);
    } catch(err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Trusted by users from {stats && stats.states ? stats.states : '35'} states
          </h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            CovidVaxMap provides real-time vaccine availability from {stats && stats.stores ? stats.stores : '19'} providers in all 50 states.
          </p>
        </div>
      </div>
      <div className="mt-10 pb-12 sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50"></div>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Users
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    {stats && stats.users ? stats.users : '100k+'}
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Searches
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    {stats && stats.searches ? stats.searches : '1m+'}
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Alerts Sent
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    {stats && stats.alerts ? stats.alerts : '500k+'}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default Stats;