import React, { useEffect, useState } from "react";

const Features = () => {
  return (
    <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Find appointments easier
            </h2>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
              Learn why CovidVaxMap is the #1 COVID-19 vaccine finder in the United States.
            </p>
          </div>

          <dl className="mt-12 pb-12 sm:pb-16 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            <div className="relative">
              <dt>
                <svg className="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">Real-time alerts</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                Sign up for real-time text message and email alerts that will notify you the minute new COVID-19 vaccine appointments become available in your area.
              </dd>
            </div>

            <div className="relative">
              <dt>
                <svg className="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">Accurate data</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                CovidVaxMap pulls data from a variety of sources, ensuring that you see the most recent and accurate data from over 30 providers.
              </dd>
            </div>

            <div className="relative">
              <dt>
                <svg className="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">Easy-to-use</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                CovidVaxMap was designed with the end user in mind. A simple map interface allows you to find COVID-19 vaccine appointments quicker and easier.
              </dd>
            </div>

            <div className="relative">
              <dt>
                <svg className="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">Works everywhere</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                CovidVaxMap works in all 50 states!
              </dd>
            </div>
          </dl>

        </div>
    </>
  );
}

export default Features;