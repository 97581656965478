import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';


const logos = ['pharmacaintegrative', 'albertsons', 'safeway', 'walgreens', 'walmart', 'samsclub', 'kroger', 'kingsoopers', 'cvs', 'riteaid', 'vons', 'sav-on', 'ralphs', 'oscodrug', 'publixsupermarkets', 'dillons', 'randalls', 'h-e-b', 'citymarket', 'meijer', 'winn-dixie', 'hy-vee', 'costco'];


const Logos = () => {

  // const data = useStaticQuery(graphql`
  //   query {
  //     allFile(
  //       filter: {
  //         extension: { regex: "/(jpg)|(png)|(jpeg)/" }
  //         relativeDirectory: { eq: "stores" }
  //       }
  //     ) {
  //       edges {
  //         node {
  //           base
  //           childImageSharp {
  //             fluid {
  //               ...GatsbyImageSharpFluid
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <div className="pb-12 bg-gray-50 lg:pt-8">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 md:max-w-5xl lg:max-w-7xl lg:px-8">
          <h1 className="text-4xl leading-none font-extrabold text-gray-900 sm:text-center">Providers</h1>
          <p className="mt-5 text-xl leading-7 text-gray-500 sm:text-center">We are currently searching the following providers and more!</p>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-8 bg-white shadow-lg rounded-lg overflow-hidden">
            {logos.map((image, index) => (
              <div key={`logo-${index}`} className="col-span-1 flex justify-center py-8 px-8 bg-white">
                <img className="max-h-16 object-contain" src={`stores/${image}-logo.png`} alt={image} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}


export default Logos;